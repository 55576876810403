import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Miomal-mobile.jpg"
import HeroTablet from "./../../images/Miomal-tablet.jpg"
import HeroDesktop from "./../../images/Miomal-desktop.jpg"
import HeroXL from "./../../images/Miomal-desktop-xl.jpg"
import Feature1 from "./../../images/miomal-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const MiomalPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "MiomalEn" }}>
    <Seo title="Miomal" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>MIOMAL</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Miomal"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Miomal
          </h5>
          <h1 className="display-4 font-light">
            For the female reproductive system
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Miomal</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For the female reproductive system
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Miomal is a modern herbal remedy whose action supports the functions
            of the female reproductive system.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/miomal-int1"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Gentle care from nature</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Gentle care from nature"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Miomal is a natural herbal product that supports the functions of
            the female reproductive system. It reduces the risk of uterine
            abnormalities associated with myoma formation, inflammation of the
            fallopian tubes, cysts and heavy bleeding.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Miomal complement
            each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Marigold flower</strong> (Flores Tagetis patellae)
                    66,0 mg
                  </p>
                  <p>
                    <strong>Blood geranium roots</strong> (Radix Geranii
                    sanguinei) 66,0 mg
                  </p>
                  <p>
                    <strong>Marsh sorrel stems</strong> (Herba Spireae ulmariae)
                    66,0 mg
                  </p>
                  <p>
                    <strong>Horsetail stalk</strong> (Herba Equiseti arvensis)
                    33,0 mg
                  </p>
                  <p>
                    <strong>Nettle herb</strong> (Herba Urticae dioicae) 33,0 mg
                  </p>
                  <p>
                    <strong>Yellow mildew</strong> (Flores Helichrisi arenarii)
                    33,0 mg
                  </p>
                  <p>
                    <strong>Whip stalks</strong> (Herba Agrimoniae) 33,0 mg
                  </p>
                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Miomal is a natural product that supports the functions of
                    the female reproductive system. Supports the activity of the
                    uterus, fallopian tubes and ovaries and helps maintain their
                    health.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default MiomalPageEn